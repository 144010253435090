<template>
  <div id="ads-price-setup" v-loading="isLoading">
    <div class="wrap">
      <el-row
        :class="isMobile ? 'content-row mobile' : 'content-row w-50'"
        v-for="ptype in list"
        :key="ptype.id"
        align="middle"
      >
        <el-col :span="isMobile ? 20 : 19">
          <span>{{ $t(ptype.position) }}</span>
          <el-button @click="handleViewSample(ptype.position)"
          class="button link-style text-primary"
          :class="isMobile ? 'small' : ''"
            >{{ $t('VIEW_SAMPLE') }}</el-button
          >
        </el-col>
        <el-col :span="isMobile ? 4 : 4">
          <el-input v-model="list[getIndex(list, ptype.id)].coin" type="number"></el-input>
        </el-col>
      </el-row>
    </div>

    <el-row type="flex" justify="center">
      <el-button
        @click="handleSubmit"
        class="button primary fixed-size-big"
      >
        {{ $t("SAVE") }}
      </el-button>
    </el-row>

    <ads-sample-dialog v-model="dialogVisible" :isPopup="isPopup" :currentValue="currentPosition" />

  </div>
</template>
  
  
  <script>
import { generalMixin } from "@/utils/general-mixin.js";
import cloneDeep from "lodash/cloneDeep";
import { mapGetters } from "vuex";
import { getAdsPriceList, updateAdsPrice } from "@/api/advertisement";
import adsSampleDialog from "@/components/advertisement/ads-sample-dialog.vue";

export default {
  name: "AdsPriceSetup",
  components: { adsSampleDialog },
  mixins: [generalMixin],
  data() {
    return {
      isLoading: false,
      coin: 250, //remove me
      listQuery: {
        fromArticle: false,
        user: { id: null },
      },
      list: [],
      dialogVisible: false,
      isPopup: false,
      currentPosition: null
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    getList() {
      this.isLoading = true;
      getAdsPriceList(this.listQuery)
        .then((res) => {
          if (res.status != "ok") return;
          this.list = cloneDeep(res.result);
        })
        .catch(this.handleError)
        .finally(() => (this.isLoading = false));
    },
    getIndex(list, id) {
      return list.findIndex((e) => e.id == id)
    },
    handleSubmit() {
      this.isLoading = true;
      let adsPriceList = this.list.map(obj => {
        let newObj = {};
        newObj.id = obj.id;
        newObj.coin = parseInt(obj.coin);
        return newObj
      })
      updateAdsPrice({adsPriceList})
        .then((res) => {
          if (res.status == "ok") {
            this.$notify({
              type: "success",
              message: this.$t("UPDATE_SUCCESSFULLY"),
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION
            });
          }
        })
        .catch(this.handleError)
        .finally(() => (this.isLoading = false,this.getList()));
    },
    handleViewSample(position) {
      this.isPopup = position === 'POPUP_DURING_ARTICLE_ENTRY' || position === 'POPUP_DURING_ARTICLE_EXIT'
      this.dialogVisible = true;
      this.currentPosition = position
    }
  },
  mounted() {
    this.listQuery.user.id = this.user.id;
    this.getList();
  },
};
</script>
  
  <style lang="scss">
@import "@/assets/style/_variables.scss";
// @import "@/assets/style/mixin/general.scss";

#ads-price-setup {
  .wrap {
    background-color: $white;
    border-radius: 10px;
    padding: 2rem;
    margin-bottom: 2rem;
  }

  .content-row {
    .el-col {
      border: 1px solid #efefef;
      border-radius: 5px;
      padding: 0.75rem 1rem;
      margin: 0.3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input.el-input__inner {
        padding: 0;
        border: none;
        text-align: center;
      }
    }
    &.mobile {
      .el-col {
        margin: 0!important;
        min-height: 52px;
      }
      .small {
        font-size: 12px;
      }
    }
  }
}

.mobile #ads-price-setup {
  .wrap {
    padding: 1rem;
    margin-bottom: 0.5rem;
  }
  .content-row {
    .el-col {
      padding: 5px 10px;
      margin: 5px;
    }
  }
}
</style>