<template>
  <div id="ads-pending-list">
    <table-action-row
      :filterList="listQuery"
      canFilterDate
      @onFilterChange="
        (value) => {
          listQuery = value;
          handleFilter();
        }
      "
    />

    <el-table 
      v-loading="isLoading" class="newsily-bo-table" :data="list"
      @row-click="handleRowClick"
      >
      <el-table-column prop="advertisement.position" :label="$t('TYPE')">
        <template slot-scope="{ row }">
          {{ row.advertisement ? $t(row.advertisement.position) : "-" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="advertisement.coin"
        :label="$t('COST')"
        width="100"
      />
      <el-table-column
        prop="advertisement.fromDate"
        :label="$t('START_DATE')"
        width="100"
      >
        <template slot-scope="{ row }">
          {{
            row.advertisement
              ? moment(row.advertisement.fromDate).format(DATE_FORMAT)
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="advertisement.toDate"
        :label="$t('END_DATE')"
        width="100"
      >
        <template slot-scope="{ row }">
          {{
            row.advertisement
              ? moment(row.advertisement.toDate).format(DATE_FORMAT)
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('ARTICLE_TITLE')" width="200">
        <template slot-scope="{ row }">
          {{ row.article.title | translate("-") }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('ARTICLE_LINK')" width="150" prop="link">
        <template slot-scope="{ row }">
          <el-link
            type="primary"
            @click="previewAds(row)"
          >
            {{ $t("CLICK_TO_OPEN") }}
          </el-link>
          <i
            class="custom-icon mini pointer icon-copy"
            @click="copyArticleLink(row.article)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="status" :label="$t('STATUS')" width="100">
        <template slot-scope="{ row }">
          <div>
            <span :class="getStatusClass(row.status)">{{
              $t(row.status)
            }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('ACTION')"
        width="100"
        class-name="small-padding"
        prop="actions"
      >
        <template slot-scope="{ row }">
          <span v-if="row.status === 'PENDING'">
            <el-button
              @click="handleApproval(row, 'APPROVE')"
              size="small"
              class="button approve"
              >{{$t('APPROVE')}}</el-button
            >
            <el-button
              @click="handleApproval(row, 'REJECT')"
              size="small"
              class="button reject"
              >{{$t('REJECT')}}</el-button
            >
          </span>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-loading="isLoading"
      v-show="total > 0"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      :total="total"
      @pagination="handleCurrentChange"
    />
  </div>
</template>
  
  
<script>
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import TableActionRow from "@/components/table/table-action-row.vue";
import Pagination from "@/components/table/pagination.vue";
import { generalMixin } from "@/utils/general-mixin.js";
import { PAGE_SIZES } from "@/constants";
import { getPendingAdsList, updateAdsApproval } from "@/api/advertisement";
import { mapGetters } from "vuex";

const filterQuery = {
  page: 1,
  search: "",
  limit: PAGE_SIZES[0],
  dateFrom: null,
  dateTo: null,
  article: {
    user: {
      id: null,
    },
  },
};

export default {
  name: "AdsPendingList",
  mixins: [generalMixin],
  components: { TableActionRow, Pagination },
  data() {
    return {
      moment: moment,
      listQuery: cloneDeep(filterQuery),
      showSearch: false,
      isLoading: false,
      total: 0,
      list: []
    };
  },
  computed: {
    ...mapGetters(["user"]),
    searchKeyword: {
      get() {
        return this.listQuery.search;
      },
      set(value) {
        this.listQuery.search = value;
      },
    },
  },
  methods: {
    previewAds(adsObj) {
      // console.log('preview',adsObj.advertisement)
      this.$store.dispatch('advertisement/previewAds',adsObj.advertisement)
      this.$nextTick(() =>  this.goTo(`/00`, true) )
      
    },
    handleReset() {
      this.listQuery = cloneDeep(filterQuery);
      this.listQuery.article.user.id = this.user.id;
      this.handleFilter();
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val.page;
      this.listQuery.limit = val.limit;
      this.getList();
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (this.showSearch == false) {
        this.searchKeyword = "";
        this.getList();
      }
    },
    handleRowClick(row, column) {
      if (column.property == 'actions' || column.property == 'link') return
      this.goTo(`/bo/advertisement/detail/0/${row.advertisement.id}/${row.id}`)
    },
    getList() {
      this.isLoading = true;
      getPendingAdsList(this.listQuery)
        .then((res) => {
          if (res.status != "ok") return;
          // console.log("Got: ", res);
          this.list = cloneDeep(res.result.list);
          this.total = res.result.total;
        })
        .catch(this.handleError)
        .finally(() => (this.isLoading = false));
    },
    handleApproval(data, action) {
      this.$confirm(this.$t(action) + "?", this.$t("ARE_YOU_SURE"), {
        confirmButtonText: this.$t("YES_WITH_ACTION", { action: this.$t(action) }),
        cancelButtonText: this.$t("CANCEL"),
        type: "warning",
      }).then(() => {
        // console.log(data,action);
        let pData = {
          id: data.id,
          actionStatus: action,
          actionBy: {
            id: this.user.id,
          },
        };
        updateAdsApproval(pData)
          .then((res) => {
            if (res.status == "ok") {
              this.$notify({
                type: "success",
                message: this.$t("UPDATE_SUCCESSFULLY"),
                duration: this.NOTIFY_DURATION,
                position: this.NOTIFY_POSITION
              });
            }
          })
          .catch(this.handleError)
          .finally(() => this.getList());
      });
    },
  },
  mounted() {
    this.handleReset();
  },
};
</script>
  
<style lang="scss">
@import "@/assets/style/mixin/table.scss";

#ads-pending-list {
  @include newsily-bo-table;

  .button {
    border-radius: 5px;
    width: 100%;
    margin: 0.1rem 0;
    padding: 0.5rem;
    height: unset;
  }
  .approve {
    background: #7dc12f 0% 0% no-repeat padding-box;
    color: #ffffff;
  }
  .reject {
    border: 1px solid #fc0d02;
    color: #f6423a;
  }
}
</style>
  