<template>
  <div id="advertisement" :class="{'mobile': isMobile}">
    <el-tabs v-model="activeTab" @tab-click="selectTab()" :stretch="isMobile">
      <el-tab-pane :label="$t('PURCHASE_LIST')" name="list" />
      <el-tab-pane :label="$t('PRICING_SETUP')" name="price"  />
      <el-tab-pane :label="$t('APPROVAL')" name="pending" />
    </el-tabs>

    <transition appear name="slide-from-right">
      <div :key="$route.fullPath" class="content">
        <ads-purchase-list v-if="activeTab === 'list'" />
        <ads-price-setup v-else-if="activeTab === 'price'"  />
        <ads-pending-list v-else-if="activeTab === 'pending'" />
      </div>
    </transition>

  </div>
</template>
  
  
<script>
import AdsPurchaseList from "./components/purchase-list"
import AdsPriceSetup from "./components/price-setup"
import AdsPendingList from "./components/pending-list"
import { generalMixin } from "@/utils/general-mixin.js"

export default {
  name: 'AdsertisementPage',
  components: { AdsPurchaseList, AdsPriceSetup, AdsPendingList },
  mixins: [generalMixin],
  data() {
    return {
      activeTab: "list",
    }
  },
  computed: {
    currentTabName() {
      switch(this.activeTab) {
        case 'list': return this.$t("PURCHASE_LIST")
        case 'price': return this.$t("PRICING_SETUP")
        default: return this.$t("APPROVAL")
      }
    }
  },
  methods: {
    selectTab() {
      let chosenTab = this.activeTab
      this.$router.replace({ ...this.$router.currentRoute, query: {chosenTab} }).catch(() => {})
    }
  },
  mounted() {
    this.activeTab = this.$route.query?.chosenTab || 'list';
  }
}
</script>
  
<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/tabs.scss";

#advertisement {
  @include mobile-tabs;

  position: relative;
  .el-tabs__header {
    position: absolute;
    top: -4rem;
    left: 25rem;

    .el-tabs__item {
      font-size: 15px;
      line-height: 32px;
    }
  }
}
</style>