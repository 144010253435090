<template>
  <div id="ads-purchase-list">
    <table-action-row
      :filterList="listQuery"
      canFilterDate
      @onFilterChange="
        (value) => {
          listQuery = value;
          handleFilter();
        }
      "
    />

    <el-table v-loading="isLoading" class="newsily-bo-table" :data="list">
      <el-table-column prop="position" :label="$t('TYPE')">
        <template slot-scope="{ row }">
          {{ $t(row.position) }}
        </template>
      </el-table-column>
      <el-table-column prop="coin" :label="$t('COST')" width="100" />
      <el-table-column prop="fromDate" :label="$t('START_DATE')" width="100">
        <template slot-scope="{ row }">
          {{ moment(row.fromDate).format(DATE_FORMAT) }}
        </template>
      </el-table-column>
      <el-table-column prop="toDate" :label="$t('END_DATE')" width="100">
        <template slot-scope="{ row }">
          {{ moment(row.toDate).format(DATE_FORMAT) }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('ARTICLE_TITLE')">
        <template slot-scope="{ row }">
          {{ row.article.title | translate("-") }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('ARTICLE_LINK')" width="150">
        <template slot-scope="{ row }">
          <el-link
            type="primary"
            @click="goToArticle(row.article,true)"
          >
            {{ $t("CLICK_TO_OPEN") }}
          </el-link>
          <i
            class="custom-icon mini pointer icon-copy"
            @click="copyArticleLink(row.article)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="status" :label="$t('STATUS')" width="100">
        <template slot-scope="{ row }">
          <div>
            <span :class="getStatusClass(row.status)">{{
              $t(row.status)
            }}</span>
            <el-dropdown
              class="action-button"
              :hide-on-click="false"
              trigger="click"
            >
              <i class="custom-icon icon-edit mini center-in-div pointer"/>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  @click.native="goTo(`/bo/advertisement/detail/0/${row.id}/0`)"
                >
                  {{ isEditable(row.status) ? $t("EDIT") : $t("VIEW") }}
                </el-dropdown-item>
                <el-dropdown-item 
                  v-if="isEditable(row.status)"
                  @click.native="handleArchive(row.id)"
                >
                  <el-divider class="no-margin" />
                  {{ $t("ARCHIVE") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-loading="isLoading"
      v-show="total > 0"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      :total="total"
      @pagination="handleCurrentChange"
    />
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import TableActionRow from "@/components/table/table-action-row.vue";
import Pagination from "@/components/table/pagination.vue";
import { generalMixin } from "@/utils/general-mixin.js";
import { PAGE_SIZES } from "@/constants";
import { getAdsList, archiveAds } from "@/api/advertisement";
import { mapGetters } from "vuex";

const filterQuery = {
  page: 1,
  search: "",
  limit: PAGE_SIZES[0],
  dateFrom: null,
  dateTo: null,
  showArchived: true,
  advertisement: {
    user: {
      id: null,
    },
  },
};

export default {
  name: "AdsPurchaseList",
  components: { TableActionRow, Pagination },
  mixins: [generalMixin],
  data() {
    return {
      moment: moment,
      listQuery: cloneDeep(filterQuery),
      showSearch: false,
      isLoading: false,
      total: 0,
      list: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
    searchKeyword: {
      get() {
        return this.listQuery.search;
      },
      set(value) {
        this.listQuery.search = value;
      },
    },
  },
  methods: {
    isEditable(status){
      // NOTE - Only status "ACTIVE" is editable
      return  status === 'ACTIVE'
    },
    handleReset() {
      this.listQuery = cloneDeep(filterQuery);
      this.listQuery.advertisement.user.id = this.user.id;
      this.handleFilter();
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val.page;
      this.listQuery.limit = val.limit;
      this.getList();
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (this.showSearch == false) {
        this.searchKeyword = "";
        this.getList();
      }
    },
    getList() {
      this.isLoading = true;
      getAdsList(this.listQuery)
        .then((res) => {
          if (res.status != "ok") return;
          // console.log("Got: ", res);
          this.list = cloneDeep(res.result.list);
          this.total = res.result.total;
        })
        .catch(this.handleError)
        .finally(() => (this.isLoading = false));
    },
    handleArchive(id) {
      this.$confirm(this.$t("ARCHIVE_CONFIRMATION"), this.$t("ARE_YOU_SURE"), {
        confirmButtonText: this.$t("ARCHIVE"),
        cancelButtonText: this.$t("CANCEL"),
        type: "warning",
      }).then(() => {
        this.isLoading = true;
        archiveAds(id)
          .then((res) => {
            if (res.status == "ok") {
              this.$notify({
                type: "success",
                message: this.$t("ARCHIVED_SUCCESSFULLY"),
                duration: this.NOTIFY_DURATION,
                position: this.NOTIFY_POSITION
              });
            }
          })
          .catch(this.handleError)
          .finally(() => this.getList());
      });
    },
  },
  mounted() {
    this.handleReset();
  }
};
</script>

<style lang="scss">
@import "@/assets/style/mixin/table.scss";

#ads-purchase-list {
  @include newsily-bo-table;
  .action-button {
    float: right;
  }
}
</style>
